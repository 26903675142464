export const UserType = [
  {
    label: "Company",
    value: "company",
  },
  {
    label: "Customer",
    value: "customer",
  },
  {
    label: "Driver",
    value: "driver",
  },
];
